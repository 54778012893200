import React from 'react';
import parse from 'html-react-parser';
import BootstrapButton from 'react-bootstrap/Button';
import Component from './Component';

export const Button = ({ children, className, ...props }) => {
  className = `opacity-100 ${className}`;
  return (
    <Component {...props} className={className} component={BootstrapButton}>
      {parse(children)}
    </Component>
  );
};

Button.displayName = 'Button';
export default Button;
