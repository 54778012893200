export const API = {
  INITIAL_STATE: {},
  GET:'GET_FROM_API'
};

export const ApiReducer = (draft, action) => {
  const { payload, type } = action;
  switch (type) {
    
    case API.GET:
        draft[payload.key] = payload.value;
        break;
        
    default:
      return draft;
  }
};
