import { i18next } from './IntlProvider';

const languages = [
	{
		code: 'xx',
		text: '',
	},
	{
		code: 'es',
		text: 'Español',
	},
	{
		code: 'it',
		text: 'Italiano',
	},
	{
		code: 'de',
		text: 'Deutsch',
	},
	{
		code: 'fr',
		text: 'Français',
	},
	{
		code: 'pt',
		text: 'Português',
	},
	{
		code: 'nl',
		text: 'Nederlands',
	},
	{
		code: 'be',
		text: 'Беларуская',
	},
	{
		code: 'el',
		text: 'Ελληνικά',
	},
	{
		code: 'en',
		text: 'English',
	},
];

export { i18next as i18n, languages };
