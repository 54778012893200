import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Wrong_emailDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const wrong_email_buttons_positiveOnClick = (options) => {
		run([{"function":"hide","dialog":"wrong_email"}], options)
	}

  return (
    
    
			<Modal id="wrong_email">
					<Modal.Header>
						<Modal.Title>{t('wrong_email.title')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{t('wrong_email.text')}
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="wrong_email_buttons_positive"
							onClick={wrong_email_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('wrong_email.wrong_email_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Wrong_emailDialog;
