import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Main_wrapperWrapper } from '../wrappers';

// Custom imports:
import { Group, Image, Text, Input } from '../components';
import logo from '../assets/images/logo.svg';
import { useTranslation } from 'react-i18next';

export const HomeView = () => {
  const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const sendButton1681131371258OnClick = (options) => {
		run([{"id":"goto1681131454461","function":"goto","view":"information"}], options)
	}
	const sendButtonOnClick = (options) => {
		run([{"id":"set1679704961801","function":"set","what":"@property.valid","value":true},{"id":"do1679705302339","function":"do","comments":"check name","what":[{"id":"set1679705318723","function":"set","what":"@property.valid","value":false},{"id":"popup1679705324653","function":"popup","dialog":"wrong_name"}],"if":{"what":"@cookie.name","is":null,"or":{"what":"@cookie.name.length","less_than":2}}},{"id":"do1679705365581","function":"do","comments":"check email","what":[{"id":"set1679705365581","function":"set","what":"@property.valid","value":false},{"id":"popup1679705365581","function":"popup","dialog":"wrong_email"}],"if":{"what":"@cookie.email","is":null,"or":{"what":"@cookie.email.length","less_than":6}}},{"id":"do1679705533365","function":"do","comments":"check message","what":[{"id":"set1679705533365","function":"set","what":"@property.valid","value":false},{"id":"popup1679705533365","function":"popup","dialog":"wrong_msg"}],"if":{"what":"@cookie.msg","is":null,"or":{"what":"@cookie.msg.length","less_than":6}}},{"id":"do1679704954489","function":"do","what":[{"id":"api1680475998675","function":"api","api":{"method":"doyo.random.pin","digits":6},"into":"@cookie.code","onsuccess":[{"id":"api1680475998675","function":"api","api":{"method":"sendgrid.sendTemplate","to":"@cookie.email","from":"info@opentextbox.com","reply_to":"noreply@opentextbox.com","template_id":"d-793ce41d361f4fcd9a184b3ce5aa0c18","dynamic_template_data":{"code":"@cookie.code"}}},{"id":"popup1680475998675","function":"popup","dialog":"email_verification"}]}],"if":{"what":"@property.valid","is":true}}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('home.title')}</title>
      </Helmet>
      <Main_wrapperWrapper>
          
			<Group
				id="homeBody"
				direction="vertical"
				className="w-100 min-h-100vh h-100vh position-relative" >
				<Group
					id="homeContentLayout"
					direction="auto"
					className="w-100 min-h-100 h-auto position-relative" >
					<Group
						id="homeContentWrapper"
						direction="vertical"
						className="min-w-300px w-fill min-h-100vh h-100vh position-relative scrollable" >
						<Group
							id="formWrapper"
							direction="horizontal"
							className="max-w-900px w-90 h-fill align-center position-relative" >
							<Group
								id="homeForm"
								direction="vertical"
								className="max-w-900px min-w-0px w-fill h-auto align-center valign-center position-relative" >
								<Group
									id="homeContent"
									direction="vertical"
									className="w-fill h-auto valign-center position-relative" >
									<Image
										id="logo"
										src={logo}
										className="max-w-300px w-fill h-auto align-start position-relative mt-20px" />
									<Text
										id="homeInstructions"
										tag="h3"
										className="w-100 h-auto position-relative mt-24px text-primary-text ff-titles text-start" >
										{t('home.homeInstructions')}
									</Text>
								</Group>
								<Group
									id="formFields"
									direction="vertical"
									className="w-100 h-auto position-relative mt-16px" >
									<Group
										id="fieldNameWrapper"
										direction="vertical"
										className="w-100 h-auto position-relative" >
										<Text
											id="fieldNameLabel"
											tag="b"
											className="w-100 h-auto position-relative mt-24px text-primary-dark ff-titles text-start" >
											{t('home.fieldNameLabel')}
										</Text>
										<Input.Text
											id="fieldNameInput"
											name="text"
											src="@cookie.name"
										size="lg"
											className="w-100 h-auto border-radius-25px position-relative bg-light mt-8px" />
									</Group>
									<Group
										id="fieldEmailWrapper"
										direction="vertical"
										className="w-100 h-auto position-relative" >
										<Text
											id="fieldEmailLabel"
											tag="b"
											className="w-100 h-auto position-relative mt-24px text-primary-dark ff-titles text-start" >
											{t('home.fieldEmailLabel')}
										</Text>
										<Input.Text
											id="fieldEmailInput"
											name="text"
											src="@cookie.email"
										pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$"
										size="lg"
											className="w-100 h-auto border-radius-25px position-relative bg-light mt-8px" />
									</Group>
									<Group
										id="fieldMsgWrapper"
										direction="vertical"
										className="w-100 h-auto position-relative" >
										<Text
											id="fieldMsgLabel"
											tag="b"
											className="w-100 h-auto position-relative mt-24px text-primary-dark ff-titles text-start" >
											{t('home.fieldMsgLabel')}
										</Text>
										<Input.TextArea
											id="fieldMsgInput"
											name="text"
											src="@cookie.msg"
										size="lg"
											className="w-100 h-auto border-radius-25px position-relative bg-light mt-8px" />
									</Group>
								</Group>
								<Text
									id="sendButton"
									onClick={sendButtonOnClick}
									className="w-196px h-auto border-radius-25px align-end position-relative bg-primary p-10px mt-48px text-text-on-primary f-16px ff-titles text-center" >
									{t('home.sendButton')}
								</Text>
							</Group>
						</Group>
						<Group
							id="homeFooter"
							direction="auto"
							className="w-100 h-auto position-relative bg-gray p-16px" >
							<Text
								id="copyrightText"
								className="w-auto h-auto valign-start position-relative text-text-on-primary ff-regular" >
								{t('home.copyrightText')}
							</Text>
							<Group
								id="group1679728790901"
								direction="vertical"
								className="w-fill h-auto position-relative" >
							</Group>
						</Group>
					</Group>
					<Group
						id="homeInfo"
						direction="vertical"
						className="max-w-500px min-w-300px w-fill min-h-100vh h-100vh position-relative bg-primary-light p-48px scrollable" >
						<Text
							id="homeAbout1679759275069"
							tag="h1"
							className="max-w-900px w-fill h-auto valign-center position-relative text-text-on-primary ff-titles text-start" >
							{t('home.homeAbout1679759275069')}
						</Text>
						<Group
							id="infoGroup1"
							direction="vertical"
							className="w-100 h-auto position-relative mt-24px" >
							<Text
								id="infoTitle1"
								className="max-w-900px w-fill h-auto valign-center position-relative text-text-on-primary ff-titles text-start" >
								{t('home.infoTitle1')}
							</Text>
							<Text
								id="infoContent1"
								className="max-w-900px w-fill h-auto valign-center position-relative text-primary-dark ff-regular text-start" >
								{t('home.infoContent1')}
							</Text>
						</Group>
						<Group
							id="infoGroup2"
							direction="vertical"
							className="w-100 h-auto position-relative mt-48px" >
							<Text
								id="infoTitle2"
								className="max-w-900px w-fill h-auto valign-center position-relative text-text-on-primary ff-titles text-start" >
								{t('home.infoTitle2')}
							</Text>
							<Text
								id="infoContent2"
								className="max-w-900px w-fill h-auto valign-center position-relative text-primary-dark ff-regular text-start" >
								{t('home.infoContent2')}
							</Text>
						</Group>
						<Group
							id="infoGroup3"
							direction="vertical"
							className="w-100 h-auto position-relative mt-48px" >
							<Text
								id="infoTitle3"
								className="max-w-900px w-fill h-auto valign-center position-relative text-text-on-primary ff-titles text-start" >
								{t('home.infoTitle3')}
							</Text>
							<Text
								id="infoContent3"
								className="max-w-900px w-fill h-auto valign-center position-relative text-primary-dark ff-regular text-start" >
								{t('home.infoContent3')}
							</Text>
						</Group>
						<Text
							id="sendButton1681131371258"
							onClick={sendButton1681131371258OnClick}
							className="w-196px h-auto border-radius-25px align-end position-relative bg-text-on-primary p-10px mt-48px text-primary f-16px ff-titles text-center" >
							{t('home.sendButton1681131371258')}
						</Text>
					</Group>
				</Group>
			</Group>
      </Main_wrapperWrapper>
    </>
  );
};
