import React from 'react';

// Custom imports:
import Email_verificationDialog from './Email_verificationDialog';
import Wrong_emailDialog from './Wrong_emailDialog';
import Wrong_msgDialog from './Wrong_msgDialog';
import Wrong_nameDialog from './Wrong_nameDialog';
import Wrong_verificationDialog from './Wrong_verificationDialog';



export const Dialogs = () => {
  return (
    <>
      
			<Email_verificationDialog />
			<Wrong_emailDialog />
			<Wrong_msgDialog />
			<Wrong_nameDialog />
			<Wrong_verificationDialog />
      
    </>
  );
};

export default Dialogs;
