import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Text, Input, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Email_verificationDialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const email_verification_buttons_positiveOnClick = (options) => {
		run([{"id":"set1680479037605","function":"set","what":"@property.valid","value":true},{"id":"set1680479054068","function":"set","what":"@property.valid","value":false,"if":{"what":"@cookie.code","is":null,"or":{"what":"@cookie.code","is_not":"@property.verification"}}},{"function":"hide","dialog":"email_verification"}], options)
	}

  return (
    
    
			<Modal id="email_verification" centered>
					<Modal.Header>
						<Modal.Title>{t('email_verification.title')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Text
							id="text1680461862898"
							className="w-100 h-auto position-relative" >
							{t('email_verification.text1680461862898')}
						</Text>
						<Input.Text
							id="codeInput"
							name="text"
							src="@property.verification"
							className="w-auto h-auto position-relative mt-24px" />
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="email_verification_buttons_positive"
							onClick={email_verification_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('email_verification.email_verification_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Email_verificationDialog;
