import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Main_wrapperWrapper } from '../wrappers';

// Custom imports:
import { Group, Text, Image } from '../components';
import email from '../assets/images/email.svg';
import user from '../assets/images/user.svg';
import stop from '../assets/images/stop.svg';
import { useTranslation } from 'react-i18next';

export const InformationView = () => {
  const { run } = useFunctions();
  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const gotoDoyoButtonOnClick = (options) => {
		run([{"id":"browser1681202021062","function":"browser","url":"https:\/\/doyo.tech","target":"_doyotech"}], options)
	}
	const instructionContent_3OnClick = (options) => {
		run([{"id":"browser1681201901704","function":"browser","url":"https:\/\/doyo.tech","target":"_doyotech"}], options)
	}
	const instructionContent_2OnClick = (options) => {
		run([{"id":"browser1681201845052","function":"browser","url":"https:\/\/doyo.tech","target":"_doyotech"}], options)
	}
	const instructionContent_1OnClick = (options) => {
		run([{"id":"browser1681201806853","function":"browser","url":"https:\/\/doyo.tech","target":"_doyotech"}], options)
	}
	const infoBannerTitleOnClick = (options) => {
		run([{"id":"goto1681203293757","function":"goto","view":"home"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('information.title')}</title>
      </Helmet>
      <Main_wrapperWrapper>
          
			<Group
				id="infoBody"
				direction="vertical"
				className="w-100 min-h-100vh h-100vh position-relative" >
				<Group
					id="infoContent"
					direction="vertical"
					className="w-100 h-fill position-relative scrollable" >
					<Group
						id="infoBanner"
						direction="vertical"
						className="w-100 h-auto position-relative bg-primary p-48px" >
						<Text
							id="infoBannerTitle"
							onClick={infoBannerTitleOnClick}
							tag="h1"
							className="w-auto h-auto align-center position-relative text-text-on-primary ff-titles" >
							{t('information.infoBannerTitle')}
						</Text>
						<Text
							id="infoBannerTitle1681202337934"
							className="max-w-800px w-auto h-auto align-center position-relative p-24px text-light ff-regular text-center" >
							{t('information.infoBannerTitle1681202337934')}
						</Text>
					</Group>
					<Group
						id="highlightsArea"
						direction="vertical"
						className="w-100 h-auto position-relative bg-light p-24px" >
						<Group
							id="highlightsWrapper"
							direction="auto"
							className="max-w-1200px w-100 h-auto align-center position-relative" >
							<Group
								id="highlight_1"
								direction="vertical"
								className="w-fill h-auto position-relative p-24px" >
								<Image
									id="img_1"
									src={stop}
									className="w-128px h-auto align-center position-relative" />
								<Text
									id="highlightText_1"
									tag="h3"
									className="w-100 h-auto position-relative mt-16px ff-regular text-center" >
									{t('information.highlightText_1')}
								</Text>
							</Group>
							<Group
								id="highlight_2"
								direction="vertical"
								className="w-fill h-auto position-relative p-24px" >
								<Image
									id="img_2"
									src={user}
									className="w-128px h-auto align-center position-relative" />
								<Text
									id="highlightText_2"
									tag="h3"
									className="w-100 h-auto position-relative mt-16px ff-regular text-center" >
									{t('information.highlightText_2')}
								</Text>
							</Group>
							<Group
								id="highlight_3"
								direction="vertical"
								className="w-fill h-auto position-relative p-24px" >
								<Image
									id="img_1681201444111"
									src={email}
									className="w-128px h-auto align-center position-relative" />
								<Text
									id="highlightText_3"
									tag="h3"
									className="w-100 h-auto position-relative mt-16px ff-regular text-center" >
									{t('information.highlightText_3')}
								</Text>
							</Group>
						</Group>
					</Group>
					<Group
						id="instructionsArea"
						direction="vertical"
						className="w-100 h-auto position-relative p-24px mt-48px" >
						<Text
							id="instructionsTitle"
							tag="h1"
							className="w-100 h-auto position-relative text-primary text-center" >
							{t('information.instructionsTitle')}
						</Text>
						<Group
							id="instruction_1"
							direction="vertical"
							className="w-100 h-auto position-relative mt-36px" >
							<Text
								id="instructionOrder_1"
								tag="h2"
								className="w-100 h-auto position-relative text-primary-dark text-center" >
								{t('information.instructionOrder_1')}
							</Text>
							<Text
								id="instructionContent_1"
								onClick={instructionContent_1OnClick}
								className="w-100 h-auto position-relative text-primary-text text-center" >
								{t('information.instructionContent_1')}
							</Text>
						</Group>
						<Group
							id="instruction_2"
							direction="vertical"
							className="w-100 h-auto position-relative mt-36px" >
							<Text
								id="instructionOrder_2"
								tag="h2"
								className="w-100 h-auto position-relative text-primary-dark text-center" >
								{t('information.instructionOrder_2')}
							</Text>
							<Text
								id="instructionContent_2"
								onClick={instructionContent_2OnClick}
								className="w-100 h-auto position-relative text-primary-text text-center" >
								{t('information.instructionContent_2')}
							</Text>
						</Group>
						<Group
							id="instruction_3"
							direction="vertical"
							className="w-100 h-auto position-relative mt-36px" >
							<Text
								id="instructionOrder_3"
								tag="h2"
								className="w-100 h-auto position-relative text-primary-dark text-center" >
								{t('information.instructionOrder_3')}
							</Text>
							<Text
								id="instructionContent_3"
								onClick={instructionContent_3OnClick}
								className="w-100 h-auto position-relative text-primary-text text-center" >
								{t('information.instructionContent_3')}
							</Text>
						</Group>
						<Text
							id="gotoDoyoButton"
							onClick={gotoDoyoButtonOnClick}
							className="w-196px h-auto border-radius-25px align-center position-relative bg-primary p-10px my-64px text-text-on-primary f-16px ff-titles text-center" >
							{t('information.gotoDoyoButton')}
						</Text>
					</Group>
				</Group>
				<Group
					id="infoFooter"
					direction="auto"
					className="w-100 h-auto position-relative bg-gray p-16px" >
					<Text
						id="copyrightText1681131496563"
						className="w-auto h-auto valign-start position-relative text-text-on-primary ff-regular" >
						{t('information.copyrightText1681131496563')}
					</Text>
					<Group
						id="group1681131496563"
						direction="vertical"
						className="w-fill h-auto position-relative" >
					</Group>
				</Group>
			</Group>
      </Main_wrapperWrapper>
    </>
  );
};
