import React from 'react';
import BootstrapLabel from 'react-bootstrap/FormLabel';
import { Component } from '.';

export const Label = ({ children, className = '', ...props }) => {
  return (
    <Component
      {...props}
      className={`form-label ${className}`.trim()}
      component={BootstrapLabel}
    >
      {children}
    </Component>
  );
};

Label.displayName = 'Label';
export default Label;
