import React from 'react';
import BootstrapModal from 'react-bootstrap/Modal';
import { Component } from '.';
import { useDialogs } from '../hooks';

export const Modal = ({ children, ...props }) => {
  const { dialogs = {}, hide } = useDialogs();
  const { [props.id]: dialog = {} } = dialogs;
  const { isShown = false } = dialog;

  const handleHide = () => {
    hide(props.id);
  };

  return (
    <Component
      {...props}
      show={isShown}
      onHide={handleHide}
      component={BootstrapModal}
    >
      {children}
    </Component>
  );
};

Modal.Body = BootstrapModal.Body;
Modal.Footer = BootstrapModal.Footer;
Modal.Header = BootstrapModal.Header;
Modal.Title = BootstrapModal.Title;

Modal.displayName = 'Modal';
export default Modal;
